import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const config = {
    apiKey: 'AIzaSyAfojSziESAYnZgIdFLCBsIQk0h2VBOT5w',
    authDomain: 'fwsheet.firebaseapp.com',
    databaseURL: 'https://fwsheet.firebaseio.com',
};

if (typeof window !== 'undefined') {
    firebase.initializeApp(config);
}

export default firebase;
